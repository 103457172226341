<template>
  <div class="app-container none-border">
    <div class="top_btns">
      <div class="left_box">
        <search @success="getList">
          <el-form style="margin:21px 0px 0px 16px;height:117px;width:430px" class="styleForm">
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <el-date-picker size="small" v-model="listQuery.period" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYYMM"></el-date-picker>
            </el-form-item>
            <el-form-item label="会计期间 ：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.beginTime"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime"></qzf-period>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div style="float:right;margin-left:10px;">
        <!-- <qzf-import-back @success="getList()" from="历史凭证"></qzf-import-back> -->
      </div>
      <div class="right_box">
        <el-button type="primary" size="small" @click="voucherAdd" plain>添加</el-button>
        <el-button type="success" size="small" @click="dayin">打印</el-button>
        <el-button type="primary" size="small" @click="daochuVoucher">导出</el-button>

      </div>
    </div>
    <div>
      <el-table :data="list" style="width: 100%" border :height="contentStyleObj" @selection-change="handleSelectionChange">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="selection" width="55" />
        <el-table-column align="center" prop="voucherDate" label="日期" width="150">
          <template #default="scope">
            <span v-if="scope.row.voucherDate">{{ scope.row.voucherDate }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="period" label="账期" width="150">
          <template #default="scope">
            <span>{{ scope.row.period}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="voucherNo" label="凭证字号" width="60">
          <template #default="scope">
            <div class="item_icon">
              <span>{{ scope.row.voucherNo }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="摘要" width="200">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.summary" :key="index">{{item}}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="科目编码" width="250">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.subject_names" :key="index">{{item}}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="借方">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.in_amount" :key="index">
              {{item}}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="贷方">
          <template #default="scope">
            <p v-for="(item,index) in scope.row.out_amount" :key="index">
              {{item}}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="billCount" label="附件张数" width="60"></el-table-column>
        
        <el-table-column align="center" prop="address" label="操作" width="180">
          <template #default="scope">
            <qzf-button @success="handleUpdate(scope.row.id)" size="small" type="text">编辑</qzf-button>
            <qzf-button size="small" @success="del(scope.row)" type="text">删除</qzf-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom_style">
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>

    <printVouchPz ref="printVouchPz" :listIds="allselect" name="book_voucher" :listQuery1="this.listQuery"></printVouchPz>
    <exportFile ref="exportFile" name="book_voucher" :listQuery1="this.listQuery"></exportFile>

  </div>
</template>

<script>
import { hisVoucherList,delHisVoucher } from "@/api/old"
import printVouchPz from "./components/printVouchPz.vue"
import exportFile from "./components/exportFile.vue"


export default {
  name: "voucherhis",
  components:{
    printVouchPz,
    exportFile
  },
  data() {
    return {
      listQuery:{
        limit:50,
        page:1,
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
      },
      list: [],
      total: 0,
      select: [],
      allselect:[],
      period:'',
      beginTime:'',
      endTime:'',
      changeTime:''
    }
  },
  created() {
    this.contentStyleObj = this.$getHeight(250)
    this.getList()
  },
  methods: {
    getList(){
      hisVoucherList(this.listQuery).then(res=>{
        if(res.data.msg == "success"){
          let list = res.data.data.list;
          list.map(item => {
            item.item_type = item.item_type.split("#|#");
            item.summary = item.summary.split("#|#");
            item.subject_code = item.subject_code.split("#|#");
            item.subject_names = item.subject_names.split("#|#");
            // item.subject_codes = item.subject_code.split("#|#");
            item.in_amount = item.in_amount.split("#|#");
            item.out_amount = item.out_amount.split("#|#");
            item.item_remark = item.item_remark.split("#|#");
            // item.fromType = item.fromType;
            // item.fromType = item.fromType;
          });
          this.list = list;
          // this.list = res.data.data.list
        }
      })
    },
    changeQujianTime(){
      this.beginTime = this.changeTime[0]
      this.endTime = this.changeTime[1]
    },

    handleSelectionChange(e){
      let arr = [];
      e.map(v => {
        arr.push(v.id);
      });
      this.allselect = arr;
    },
    // 编辑
    handleUpdate(e) {
      this.$store.dispatch('commons/setParam', {historyVoucherId: e,hisVoucherPeriod: this.listQuery.period})
      this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
      this.$router.push({
        path: "/bookkeeps/components/historyVoucher",
        name: "historyVoucher",
      });
    },
    //新增
    voucherAdd() {
      this.$store.dispatch('commons/setParam', {hisVoucherPeriod: this.listQuery.period,historyVoucherId: 0})
      this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
      this.$router.push({
        path: "/bookkeeps/components/historyVoucher",
        name: "historyVoucher",
      });
    },
    dayin() {
      this.$refs.printVouchPz.init()
    },
    daochuVoucher(){
      this.$refs.exportFile.init()
    },
    del(e) {
      this.$confirm("确认删除该凭证", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'

      }).then(() => {
        delHisVoucher({ id: e.id}).then(res => {
          if(res.data.msg == 'success'){
            this.$message({
              type: "success",
              message: "删除成功"
            });
            this.getList();
          }
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
 
.none-border p {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.none-border p:first-child {
  border-top: none;
}
.top_btns {
  .left_box{
    float: left;
    width: 70%;
    margin-bottom: 10px;
  }
  .right_box {
    float: right;
    width: 20%;
    text-align: right;
  }
}
</style>
<style>
.none-border .cell {
  padding: 0;
}
.none-border .el-table__row td {
  padding: 0 !important;
}

.none-border .cell {
  padding: 0;
  text-align: center;
}
</style>